import {
  Toolbar,
  type ToolbarProps,
} from '@devexpress/dx-react-grid-material-ui';

import {OptixTableToolbarRoot} from './OptixTableToolbarRoot';

export default function OptixTableToolbar(props: ToolbarProps) {
  return <Toolbar rootComponent={OptixTableToolbarRoot} {...props} />;
}
