import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {OptixTableDataTypeDateTimeEditor} from './OptixTableDataTypeDateTimeEditor';
import {OptixTableDataTypeDateTimeFormatter} from './OptixTableDataTypeDateTimeFormatter';

export default function OptixTableDataTypeDateTimeProvider(
  props: Pick<DataTypeProviderProps, 'for'>,
) {
  return (
    <DataTypeProvider
      editorComponent={OptixTableDataTypeDateTimeEditor}
      formatterComponent={OptixTableDataTypeDateTimeFormatter}
      {...props}
    />
  );
}
