import {useCallback} from 'react';

import Close from '@mui/icons-material/Close';

import {OptixTableActionsButton} from './OptixTableActionsButton';
import {useOptixTableActionsProviderContext} from '../OptixTableActionsProviderContext';

interface OptixTableCancelActionProps<TData extends object> {
  row: TData;
}

export function OptixTableCancelAction<TData extends object>({
  row,
}: OptixTableCancelActionProps<TData>) {
  const {setEditingRows, setAddedRows} = useOptixTableActionsProviderContext();

  const handleCancel = useCallback(() => {
    if (row != null) {
      setEditingRows((prevState) => prevState.filter((state) => state !== row));
      setAddedRows((prevState) => prevState.filter((state) => state !== row));
    }
  }, [row, setAddedRows, setEditingRows]);

  return (
    <OptixTableActionsButton
      text="Cancel"
      icon={<Close />}
      onClick={handleCancel}
    />
  );
}
