import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';
import Refresh from '@mui/icons-material/Refresh';

import {OptixButton} from '../../button';
import {useOptixTableContext} from '../OptixTableContext';

export default function OptixTableToolbarRefresh() {
  const {onRefresh} = useOptixTableContext();

  const handleHandleRefreshRow = () => {
    if (onRefresh != null) {
      onRefresh();
    }
  };

  return (
    <Plugin dependencies={[{name: 'Toolbar'}]} name="TableToolbarRefresh">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
          <OptixButton
            text="Refresh"
            startIcon={<Refresh />}
            onClick={handleHandleRefreshRow}
          />
        </div>
      </Template>
    </Plugin>
  );
}
