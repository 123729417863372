import {
  type MouseEventHandler,
  type PropsWithChildren,
  useCallback,
  useState,
} from 'react';

import {OptixDialog, type OptixDialogProps} from './OptixDialog';
import {OptixButton, type OptixButtonProps} from '../button';

export interface OptixDialogButtonProps {
  /** Button props */
  text: string;
  color?: OptixButtonProps['color'];
  size?: OptixButtonProps['size'];
  variant?: OptixButtonProps['variant'];
  sx?: OptixButtonProps['sx'];
  className?: OptixButtonProps['className'];
  startIcon?: OptixButtonProps['startIcon'];
  endIcon?: OptixButtonProps['endIcon'];
  disabled?: OptixButtonProps['disabled'];
  loading?: OptixButtonProps['loading'];
  loadingText?: OptixButtonProps['loadingText'];
  type?: OptixButtonProps['type'];
  /** Dialog props */
  dialogTitle: string;
  dialogMessage?: string;
  acceptText?: string;
  enableAccept?: boolean;
  enableCloseOnAccept?: boolean;
  denyText?: string;
  enableDeny?: boolean;
  enableCloseOnDeny?: boolean;
  onAccept?: MouseEventHandler<HTMLButtonElement>;
  onDeny?: MouseEventHandler<HTMLButtonElement>;
  dialogProps?: Omit<
    OptixDialogProps,
    | 'open'
    | 'onClose'
    | 'title'
    | 'onAccept'
    | 'onDeny'
    | 'message'
    | 'enableAccept'
    | 'enableCloseOnAccept'
    | 'enableDeny'
    | 'enableCloseOnDeny'
  >;
}

export function OptixDialogButton({
  text,
  dialogTitle,
  dialogMessage,
  acceptText,
  enableAccept,
  enableCloseOnAccept,
  denyText,
  enableDeny,
  enableCloseOnDeny,
  onAccept,
  onDeny,
  dialogProps,
  children,
  ...buttonProps
}: PropsWithChildren<OptixDialogButtonProps>) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <OptixButton text={text} onClick={handleOpen} {...buttonProps} />
      <OptixDialog
        open={open}
        onClose={handleClose}
        title={dialogTitle}
        message={dialogMessage}
        onAccept={onAccept}
        onDeny={onDeny}
        acceptText={acceptText}
        enableAccept={enableAccept}
        enableCloseOnAccept={enableCloseOnAccept}
        denyText={denyText}
        enableDeny={enableDeny}
        enableCloseOnDeny={enableCloseOnDeny}
        {...dialogProps}
      >
        {children}
      </OptixDialog>
    </>
  );
}
