import {type ReactNode} from 'react';

import Checkbox, {type CheckboxProps} from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, {
  type FormControlLabelProps,
} from '@mui/material/FormControlLabel';

import {StyledCheckboxHelper, StyledOptixCheckbox} from './OptixCheckbox.style';

export interface OptixCheckboxProps extends CheckboxProps {
  error?: boolean;
  helperText?: string;
  label?: ReactNode;
  labelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
}

export function OptixCheckbox({
  error,
  size = 'medium',
  helperText,
  label,
  labelProps,
  ...props
}: OptixCheckboxProps) {
  if (label != null) {
    return (
      <FormControl component="fieldset" variant="standard" error={error}>
        <FormControlLabel
          {...labelProps}
          sx={{ml: 0}}
          control={<Checkbox size={size} {...props} />}
          label={label}
        />
        {helperText != null ? (
          <StyledCheckboxHelper>{helperText}</StyledCheckboxHelper>
        ) : null}
      </FormControl>
    );
  }

  return <StyledOptixCheckbox size={size} {...props} />;
}
