import {useCallback, useRef, useState} from 'react';

import Check from '@mui/icons-material/Check';
import Collapse from '@mui/material/Collapse';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import {OptixIconButton} from '../../../button';
import {OptixCheckbox} from '../../../checkbox/OptixCheckbox';
import {type FilterOption} from '../../OptixTable.types';
import {useOptixTableContext} from '../../OptixTableContext';
import {Field} from '../Field';

interface OptixTableToolbarSearchMoreOptionProps {
  option: FilterOption;
}

export function OptixTableToolbarSearchMoreOption({
  option,
}: OptixTableToolbarSearchMoreOptionProps) {
  const {filters, onFiltersChange} = useOptixTableContext();
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const renderSearchElement = useCallback(() => {
    const currentValue = filters?.find(({name}) => name === option.name)?.value;
    switch (option.type) {
      case 'boolean': {
        let label = 'Indeterminate';
        if (currentValue === true) {
          label = 'True';
        }
        if (currentValue === false) {
          label = 'False';
        }
        return (
          <OptixCheckbox
            indeterminate={currentValue == null}
            checked={currentValue === true}
            label={label}
            onChange={(_event, checked) => {
              if (onFiltersChange != null) {
                onFiltersChange([
                  ...(filters?.filter(
                    (filter) => filter.name !== option.name,
                  ) ?? []),
                  {...option, value: checked},
                ]);
              }
            }}
          />
        );
      }
      default:
        return (
          <Field
            inputRef={inputRef}
            placeholder={`Search ${option.label}`}
            onKeyDown={(event) => {
              if (
                event.key === 'Enter' &&
                onFiltersChange != null &&
                inputRef.current?.value != null
              ) {
                onFiltersChange([
                  ...(filters?.filter(
                    (filter) => filter.name !== option.name,
                  ) ?? []),
                  {
                    ...option,
                    value: inputRef.current.value,
                  },
                ]);
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <OptixIconButton
                  color="primary"
                  onClick={() => {
                    if (
                      onFiltersChange != null &&
                      inputRef.current?.value != null
                    ) {
                      onFiltersChange([
                        ...(filters?.filter(
                          (filter) => filter.name !== option.name,
                        ) ?? []),
                        {
                          ...option,
                          value: inputRef.current.value,
                        },
                      ]);
                    }
                  }}
                >
                  <Check />
                </OptixIconButton>
              </InputAdornment>
            }
            defaultValue={currentValue}
          />
        );
    }
  }, [filters, onFiltersChange, option]);

  return (
    <Stack>
      <MenuItem
        onClick={() => {
          setOpen((prevState) => !prevState);
        }}
      >
        {option.label}
      </MenuItem>
      <Collapse in={open}>
        <Stack px={2} py={1} alignItems="flex-start">
          {renderSearchElement()}
        </Stack>
      </Collapse>
    </Stack>
  );
}
