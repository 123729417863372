import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {OptixTableDataTypeDateEditor} from './OptixTableDataTypeDateEditor';
import {OptixTableDataTypeDateFormatter} from './OptixTableDataTypeDateFormatter';

export default function OptixTableDataTypeDateProvider(
  props: Pick<DataTypeProviderProps, 'for'>,
) {
  return (
    <DataTypeProvider
      editorComponent={OptixTableDataTypeDateEditor}
      formatterComponent={OptixTableDataTypeDateFormatter}
      {...props}
    />
  );
}
