import {DataTypeProvider} from '@devexpress/dx-react-grid';

import {
  OptixTableActionsProviderContext,
  type OptixTableActionsProviderProps,
} from './OptixTableActionsProviderContext';
import OptixTableActionsProviderEditor from './OptixTableActionsProviderEditor';
import OptixTableActionsProviderFormatter from './OptixTableActionsProviderFormatter';

export default function OptixTableDataTypeActionsProvider<TData = object>(
  props: OptixTableActionsProviderProps<TData>,
) {
  return (
    <DataTypeProvider
      for={['actions']}
      // TODO
      // eslint-disable-next-line react/no-unstable-nested-components
      editorComponent={(editorProps) => (
        <OptixTableActionsProviderContext.Provider
          value={props as OptixTableActionsProviderProps<unknown>}
        >
          <OptixTableActionsProviderEditor {...editorProps} />
        </OptixTableActionsProviderContext.Provider>
      )}
      // TODO
      // eslint-disable-next-line react/no-unstable-nested-components
      formatterComponent={(formatterProps) => (
        <OptixTableActionsProviderContext.Provider
          value={props as OptixTableActionsProviderProps<unknown>}
        >
          <OptixTableActionsProviderFormatter {...formatterProps} />
        </OptixTableActionsProviderContext.Provider>
      )}
    />
  );
}
