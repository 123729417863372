import {useState, type MouseEvent} from 'react';

import Add from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';

import {OptixTableToolbarSearchMoreOption} from './OptixTableToolbarSearchMoreOption';
import {OptixButton} from '../../../button';
import {useOptixTableContext} from '../../OptixTableContext';

export function OptixTableToolbarSearchMore() {
  const {filterOptions} = useOptixTableContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <OptixButton color="secondary" startIcon={<Add />} onClick={handleClick}>
        Add Filter
      </OptixButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {filterOptions?.map((option) => (
          <OptixTableToolbarSearchMoreOption
            key={option.name}
            option={option}
          />
        ))}
      </Menu>
    </>
  );
}
