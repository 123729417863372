import {type ChangeEvent, useCallback} from 'react';

import {type SearchPanel} from '@devexpress/dx-react-grid-material-ui';
import Search from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';

import {OptixTableToolbarSearchMore} from './OptixTableToolbarSearchMore';
import {useOptixTableContext} from '../../OptixTableContext';
import {Field} from '../Field';

export default function OptixTableToolbarSearch({
  value,
  onValueChange,
}: SearchPanel.InputProps) {
  const {searchText, filterOptions, filters, onFiltersChange} =
    useOptixTableContext();
  const handleValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onValueChange(event.target.value);
    },
    [onValueChange],
  );

  return (
    <>
      {filterOptions != null && filterOptions.length > 0 && (
        <OptixTableToolbarSearchMore />
      )}
      {filters?.map((filter) => (
        <Chip
          color="secondary"
          key={filter.name}
          label={filter.label}
          onDelete={() => {
            if (onFiltersChange != null) {
              onFiltersChange(filters.filter(({name}) => name !== filter.name));
            }
          }}
        />
      ))}
      <Field
        placeholder={searchText ?? 'Search'}
        endAdornment={
          <InputAdornment position="end">
            <Search color="action" />
          </InputAdornment>
        }
        value={value}
        onChange={handleValueChange}
      />
    </>
  );
}
