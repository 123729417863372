import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';

import {Loader} from '../../../../modules/common/components/Loader';

interface OptixTableToolbarLoaderProps {
  loading: boolean;
}

export default function OptixTableToolbarLoader({
  loading,
}: OptixTableToolbarLoaderProps) {
  return (
    <Plugin dependencies={[{name: 'Toolbar'}]} name="TableToolbarLoader">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <Loader loading={loading} />
      </Template>
    </Plugin>
  );
}
