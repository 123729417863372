import {useCallback, useMemo} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';
import {type SelectChangeEvent} from '@mui/material/Select';

import {OptixSelect} from '../../../select/OptixSelect';
import {useOptixTableContext} from '../../OptixTableContext';

export function OptixTableDataTypeSelectEditor({
  value,
  onValueChange,
  column,
}: DataTypeProvider.ValueEditorProps) {
  const {config} = useOptixTableContext();
  const columnConfig = useMemo(
    () => config.columns.find(({name}) => column.name === name),
    [column.name, config.columns],
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      onValueChange(event.target.value);
    },
    [onValueChange],
  );
  return (
    <OptixSelect
      size="small"
      options={
        columnConfig != null &&
        'type' in columnConfig &&
        columnConfig.type === 'select' &&
        columnConfig.options != null
          ? columnConfig.options
          : []
      }
      value={value}
      label="Category"
      onChange={handleChange}
    />
  );
}
