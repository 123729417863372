import {
  type Dispatch,
  type SetStateAction,
  Suspense,
  useMemo,
  useState,
} from 'react';

import {
  CustomPaging,
  PagingState,
  SearchState,
  type Column,
  EditingState,
  SortingState,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  SearchPanel,
  TableEditRow,
  TableFixedColumns,
  TableColumnResizing,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';
import {debounce} from '@mui/material/utils';

import {
  type OptixColumnExtentions,
  type OptixTableProps,
} from './OptixTable.types';
import {OptixTableContext} from './OptixTableContext';
import {OptixTableBase} from './tableBase/OptixTableBase';
import {TableFixedColumnsCell} from './tableBase/TableFixedColumnsCell';
import OptixTableDataTypeAutocompleteProvider from './tableDataTypeProviders/optixTableDataTypeAutocompleteProvider/OptixTableDataTypeAutocompleteProvider';
import OptixTableDataTypeActionsProvider from './tableDataTypeProviders/tableDataTypeActionsProvider/OptixTableDataTypeActionsProvider';
import OptixTableDataTypeBooleanProvider from './tableDataTypeProviders/tableDataTypeBooleanProvider/OptixTableDataTypeBooleanProvider';
import OptixTableDataTypeDateProvider from './tableDataTypeProviders/tableDataTypeDateProvider/OptixTableDataTypeDateProvider';
import OptixTableDataTypeDateTimeProvider from './tableDataTypeProviders/tableDataTypeDateTimeProvider/OptixTableDataTypeDateTimeProvider';
import OptixTableDataTypeNumberProvider from './tableDataTypeProviders/tableDataTypeNumberProvider/OptixTableDataTypeNumberProvider';
import OptixTableDataTypeSelectProvider from './tableDataTypeProviders/tableDataTypeSelectProvider/OptixTableDataTypeSelectProvider';
import OptixTableDataTypeStringProvider from './tableDataTypeProviders/tableDataTypeStringProvider/OptixTableDataTypeStringProvider';
import OptixTableDataTypeTimeProvider from './tableDataTypeProviders/tableDataTypeTimeProvider/OptixTableDataTypeTimeProvider';
import OptixTablePaging from './tableFooter/OptixTablePaging';
import {OptixTableGrid} from './tableGrid/OptixTableGrid';
import {OptixTableHeaderRow} from './tableHeaderRow';
import OptixTableToolbar from './tableToolbar';
import OptixTableToolbarCustom from './tableToolbar/OptixTableToolbarCustom';
import OptixTableToolbarLoader from './tableToolbar/OptixTableToolbarLoader';
import OptixTableToolbarRefresh from './tableToolbar/OptixTableToolbarRefresh';
import OptixTableToolbarSearch from './tableToolbar/tableToolbarSearch/OptixTableToolbarSearch';
import {DEFAULT_PAGE_SIZE} from '../../../modules/common/constants/DEFAULT_PAGE_SIZE';

export function OptixTable<TData = object>(props: OptixTableProps<TData>) {
  const {
    // Required
    config,
    list,
    // States
    loading,
    loadingText,
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    rowId,
    total = 0,
    sorting,
    // Components
    CustomActions,
    RowDetails,
    // Handlers
    onDialogAdd,
    onInlineAdd,
    showQR,
    onDialogEdit,
    onInlineEdit,
    onDelete,
    onPageChange,
    onPageSizeChange,
    onSearch,
    onSortingChange,
    onRefresh,
    // Props
    PagingPanelProps,
    PagingStateProps,
    SearchPanelProps,
    SearchStateProps,
    TableHeaderRowProps,
    TableProps,
    ToolbarProps,
  } = props;
  const [editingRows, setEditingRows] = useState<TData[]>([]);
  const [addedRows, setAddedRows] = useState<TData[]>([]);
  const [rowChanges, setRowChanges] = useState<Record<string, unknown>>({});
  const [expandedRowIds, setExpandedRowIds] = useState<Array<string | number>>(
    [],
  );
  const {
    enableAdd,
    enableViewQR,
    enableEdit,
    enableDelete,
    enableActions,
    enableRefresh,
    columns,
    columnExt,
    actionsWidth,
    columnWidths,
    sortableColumns,
    booleanColumns,
    stringColumns,
    numberColumns,
    dateColumns,
    dateTimeColumns,
    timeColumns,
    selectColumns,
    autoCompleteColumns,
  } = useMemo(() => {
    const scopedEnableAdd = onDialogAdd != null || onInlineAdd != null;
    const scopedEnableEdit = onDialogEdit != null || onInlineEdit != null;
    const scopedEnableDelete = onDelete != null;
    const scopedEnableRefresh = onRefresh != null;
    const scopedEnableQR = showQR != null;

    const scopedEnableActions =
      scopedEnableAdd || scopedEnableEdit || scopedEnableDelete;

    const configColumns: Column[] = config.columns.map(
      ({name, label, getValue, enableEditing}) => ({
        name: String(name),
        title: label,
        getCellValue: getValue,
        editingEnabled: enableEditing,
      }),
    );

    const columnExtensions: OptixColumnExtentions[] = config.columns.map(
      ({name, enableEditing}) => ({
        columnName: String(name),
        editingEnabled: enableEditing,
      }),
    );

    if (scopedEnableActions) {
      const actionsColumn: Column = {
        name: 'actions',
        getCellValue: undefined,
        title: 'Actions' as string | undefined,
      };
      configColumns.push(actionsColumn);
    }

    let numOfInlineActions = 0;
    let numOfTableActions = 0;

    if (scopedEnableAdd) {
      numOfTableActions += 1;
    }
    if (scopedEnableEdit) {
      numOfInlineActions += 1;
    }
    if (scopedEnableDelete) {
      numOfInlineActions += 1;
    }

    const amountOfActions =
      numOfInlineActions > numOfTableActions
        ? numOfInlineActions
        : numOfTableActions;
    const actionsWidth =
      (amountOfActions > 1 ? 45 : 100) + amountOfActions * 60;

    return {
      enableViewQR: scopedEnableQR,
      enableAdd: scopedEnableAdd,
      enableEdit: scopedEnableEdit,
      enableDelete: scopedEnableDelete,
      enableActions: scopedEnableActions,
      enableRefresh: scopedEnableRefresh,
      actionsWidth,
      columnExt: columnExtensions,
      columns: configColumns,
      columnWidths: configColumns.map((c) => ({
        columnName: c.name,
        width: c.name === 'actions' ? actionsWidth : 'auto',
      })),
      sortableColumns: config.columns.map(({name, enableSort}) => ({
        columnName: String(name),
        sortingEnabled: enableSort !== false,
      })),
      booleanColumns: config.columns
        .filter((column) => 'type' in column && column.type === 'boolean')
        .map(({name}) => String(name)),
      stringColumns: config.columns
        .filter((column) => 'type' in column && column.type === 'string')
        .map(({name}) => String(name)),
      numberColumns: config.columns
        .filter((column) => 'type' in column && column.type === 'number')
        .map(({name}) => String(name)),
      dateColumns: config.columns
        .filter((column) => 'type' in column && column.type === 'date')
        .map(({name}) => String(name)),
      dateTimeColumns: config.columns
        .filter((column) => 'type' in column && column.type === 'datetime')
        .map(({name}) => String(name)),
      timeColumns: config.columns
        .filter((column) => 'type' in column && column.type === 'time')
        .map(({name}) => String(name)),
      autoCompleteColumns: config.columns
        .filter((column) => 'type' in column && column.type === 'autocomplete')
        .map(({name}) => String(name)),
      multiAutocompleteColumns: config.columns
        .filter(
          (column) => 'type' in column && column.type === 'multi-autocomplete',
        )
        .map(({name}) => String(name)),
      selectColumns: config.columns
        .filter((column) => 'type' in column && column.type === 'select')
        .map(({name}) => String(name)),
      multiSelectColumns: config.columns
        .filter((column) => 'type' in column && column.type === 'multi-select')
        .map(({name}) => String(name)),
    };
  }, [
    onDialogAdd,
    onInlineAdd,
    onDialogEdit,
    onInlineEdit,
    onDelete,
    onRefresh,
    showQR,
    config.columns,
  ]);

  const enableToolbarLoading = loading !== undefined;
  const enableToolbarSearch = onSearch != null;
  const enableToolbar = true;

  const enablePaging = onPageChange != null;
  const enableSorting = onSortingChange != null && sorting != null;

  return (
    <Suspense fallback={null}>
      <OptixTableContext.Provider value={props as OptixTableProps<unknown>}>
        <OptixTableGrid columns={columns} rows={list}>
          {enableActions && (
            <OptixTableDataTypeActionsProvider
              enableQR={enableViewQR}
              enableAdd={enableAdd}
              enableEdit={enableEdit}
              enableDelete={enableDelete}
              addedRows={addedRows}
              rowChanges={rowChanges}
              setAddedRows={setAddedRows as Dispatch<SetStateAction<unknown>>}
              editingRows={editingRows}
              setEditingRows={
                setEditingRows as Dispatch<SetStateAction<unknown>>
              }
              expandedRowsId={expandedRowIds}
              setExpandedRowsId={setExpandedRowIds}
            />
          )}
          {booleanColumns.length > 0 && (
            <OptixTableDataTypeBooleanProvider for={booleanColumns} />
          )}
          {stringColumns.length > 0 && (
            <OptixTableDataTypeStringProvider for={stringColumns} />
          )}
          {numberColumns.length > 0 && (
            <OptixTableDataTypeNumberProvider for={numberColumns} />
          )}
          {dateColumns.length > 0 && (
            <OptixTableDataTypeDateProvider for={dateColumns} />
          )}
          {dateTimeColumns.length > 0 && (
            <OptixTableDataTypeDateTimeProvider for={dateTimeColumns} />
          )}
          {timeColumns.length > 0 && (
            <OptixTableDataTypeTimeProvider for={timeColumns} />
          )}
          {selectColumns.length > 0 && (
            <OptixTableDataTypeSelectProvider for={selectColumns} />
          )}
          {autoCompleteColumns.length > 0 && (
            <OptixTableDataTypeAutocompleteProvider for={autoCompleteColumns} />
          )}
          <EditingState
            editingRowIds={editingRows
              .filter(
                (row) =>
                  row != null &&
                  typeof row === 'object' &&
                  rowId in row &&
                  (row as Record<string, unknown>)[rowId] != null,
              )
              .map(
                (row) =>
                  (row as Record<string, unknown>)[rowId] as string | number,
              )}
            rowChanges={rowChanges}
            onRowChangesChange={setRowChanges}
            addedRows={addedRows}
            columnExtensions={columnExt}
            onCommitChanges={() => {}}
          />
          {enableToolbarSearch && (
            <SearchState
              onValueChange={debounce(onSearch, 1000)}
              {...SearchStateProps}
            />
          )}
          {enableSorting && (
            <SortingState
              sorting={sorting}
              onSortingChange={onSortingChange}
              columnExtensions={sortableColumns}
            />
          )}
          {enablePaging && (
            <PagingState
              currentPage={page}
              onCurrentPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              pageSize={pageSize}
              {...PagingStateProps}
            />
          )}
          {RowDetails != null && (
            <RowDetailState
              expandedRowIds={expandedRowIds}
              onExpandedRowIdsChange={setExpandedRowIds}
            />
          )}
          {enablePaging && <CustomPaging totalCount={total} />}
          <OptixTableBase
            colSpan={columns.length}
            loading={loading}
            loadingText={loadingText}
            {...TableProps}
          />
          <TableColumnResizing
            defaultColumnWidths={columnWidths}
            resizingMode="nextColumn"
            columnExtensions={[
              {
                columnName: 'actions',
                maxWidth: actionsWidth,
                minWidth: actionsWidth,
              },
            ]}
          />
          <OptixTableHeaderRow
            {...TableHeaderRowProps}
            showSortingControls={enableSorting}
          />
          {RowDetails != null && (
            <TableRowDetail
              contentComponent={RowDetails}
              cellComponent={VantageTableRowDetailCellComponent}
            />
          )}
          {config.disableToolbar !== true && (
            <OptixTableToolbar {...ToolbarProps} />
          )}
          {enableRefresh ? <OptixTableToolbarRefresh /> : null}
          {config.disableToolbar !== true &&
            enableToolbar &&
            enableToolbarLoading && (
              <OptixTableToolbarLoader loading={loading} />
            )}
          {CustomActions != null && (
            <OptixTableToolbarCustom CustomActions={CustomActions} />
          )}
          <TableEditRow />
          {enableActions && (
            <TableFixedColumns
              cellComponent={TableFixedColumnsCell}
              rightColumns={['actions']}
            />
          )}
          {enableToolbarSearch && (
            <SearchPanel
              inputComponent={OptixTableToolbarSearch}
              {...SearchPanelProps}
            />
          )}
          {enablePaging && <OptixTablePaging {...PagingPanelProps} />}
        </OptixTableGrid>
      </OptixTableContext.Provider>
    </Suspense>
  );
}

const VantageTableRowDetailCellComponent = styled(TableRowDetail.Cell)({
  padding: 0,
  borderBottomWidth: 0,
});
