import {type CSSProperties, useCallback} from 'react';

import {
  type Table,
  VirtualTable,
  type VirtualTableProps,
} from '@devexpress/dx-react-grid-material-ui';

import {
  OptixTableBaseNoDataRow,
  type OptixTableBaseNoDataRowProps,
} from './OptixTableBaseNoDataRow';
import {OptixTableContainer} from './OptixTableContainer';

type OptixTableBaseProps = VirtualTableProps & OptixTableBaseNoDataRowProps;

export const cellStyle: CSSProperties = {
  paddingTop: '0',
  paddingBottom: '0',
  minHeight: '2.5rem',
  height: '2.5rem',
  maxHeight: '2.5rem',
};

function Cell({children, ...props}: Table.DataCellProps) {
  return (
    <VirtualTable.Cell style={cellStyle} {...props}>
      {children}
    </VirtualTable.Cell>
  );
}

function StubCell(props: Table.CellProps) {
  return <VirtualTable.StubCell style={cellStyle} {...props} />;
}

export function OptixTableBase({
  loading,
  loadingText,
  colSpan,
  ...props
}: OptixTableBaseProps) {
  const noDataLoading = useCallback(
    ({children, ...p}: Table.RowProps) => (
      <OptixTableBaseNoDataRow
        loading={loading}
        loadingText={loadingText}
        colSpan={colSpan}
        {...p}
      >
        {children}
      </OptixTableBaseNoDataRow>
    ),
    [colSpan, loading, loadingText],
  );

  return (
    <VirtualTable
      containerComponent={OptixTableContainer}
      noDataRowComponent={noDataLoading}
      cellComponent={Cell}
      stubCellComponent={StubCell}
      {...props}
    />
  );
}
