import {DateTime} from 'luxon';

export function FormatDate(
  date: string | Date | null | undefined,
  format: 'date' | 'datetime' | 'time',
) {
  if (date == null) {
    return null;
  }

  let luxonDate: DateTime | null;

  if (typeof date === 'string') {
    luxonDate = DateTime.fromISO(date);
  } else {
    luxonDate = DateTime.fromJSDate(date);
  }
  let luxonFormat = 'dd/MM/yyyy';

  if (format === 'datetime') {
    luxonFormat = 'dd/MM/yyyy HH:mm';
  } else if (format === 'time') {
    luxonFormat = 'HH:mm';
  }

  return luxonDate.toFormat(luxonFormat);
}
