import {type ChangeEvent, type KeyboardEventHandler, useCallback} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';

import {OptixTextField} from '../../../textField';

export function OptixTableDataTypeDateTimeEditor({
  value,
  onValueChange,
  onBlur,
  onFocus,
  onKeyDown,
}: DataTypeProvider.ValueEditorProps) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onValueChange(event.target.value);
    },
    [onValueChange],
  );

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        onKeyDown({key: event.key});
      }
    },
    [onKeyDown],
  );

  return (
    <OptixTextField
      size="small"
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
    />
  );
}
