import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {OptixTableDataTypeAutocompleteEditor} from './OptixTableDataTypeAutocompleteEditor';
import {OptixTableDataTypeAutocompleteFormatter} from './OptixTableDataTypeAutocompleteFormatter';

export default function OptixTableDataTypeAutocompleteProvider(
  props: Pick<DataTypeProviderProps, 'for'>,
) {
  return (
    <DataTypeProvider
      editorComponent={OptixTableDataTypeAutocompleteEditor}
      formatterComponent={OptixTableDataTypeAutocompleteFormatter}
      {...props}
    />
  );
}
