import {type ReactNode} from 'react';

import ApartmentIcon from '@mui/icons-material/Apartment';
import Article from '@mui/icons-material/Article';
import Badge from '@mui/icons-material/Badge';
import CalendarToday from '@mui/icons-material/CalendarToday';
import ChatIcon from '@mui/icons-material/Chat';
import ChecklistIcon from '@mui/icons-material/Checklist';
import EditCalendar from '@mui/icons-material/EditCalendar';
import GroupsIcon from '@mui/icons-material/Groups';
import Home from '@mui/icons-material/Home';
import Psychology from '@mui/icons-material/Psychology';
import TouchApp from '@mui/icons-material/TouchApp';
import Upload from '@mui/icons-material/Upload';

import {
  empowerMeViewRoles,
  recruitMeViewRoles,
  type Role,
  trainMeViewRoles,
} from './roles';
import {routes} from './routes';

export interface NavigationLink {
  path: string;
  icon: ReactNode;
  name: string;
  enableClick?: boolean;
  roles: Role[];
}

export const homeLinks: NavigationLink[] = [
  {
    name: 'Home',
    icon: <Home />,
    path: routes.landing,
    roles: [],
  },
  {
    name: 'Empower Me',
    icon: <Article />,
    path: routes.empowerMe.get(),
    roles: empowerMeViewRoles,
  },
  {
    name: 'Recruit Me',
    icon: <TouchApp />,
    path: routes.recruitMe.assessmentChat,
    roles: recruitMeViewRoles,
  },
  {
    name: 'Train Me',
    icon: <Psychology />,
    path: routes.trainMe.scheduler,
    roles: trainMeViewRoles,
  },
];

export const empowerMeLinks: NavigationLink[] = [
  {
    name: 'Empower Me',
    icon: <Home />,
    path: routes.empowerMe.get(),
    roles: empowerMeViewRoles,
  },
  {
    name: 'Reason Codes',
    icon: <CalendarToday />,
    path: routes.empowerMe.events,
    roles: ['eventactivityreasoncode:read'],
  },
  {
    name: 'Drivers',
    icon: <Badge />,
    path: routes.empowerMe.drivers,
    roles: ['driver:read'],
  },
  {
    name: 'Behaviours',
    icon: <TouchApp />,
    path: routes.empowerMe.behaviours,
    roles: ['eventbehaviour:read'],
  },
  {
    name: 'Training',
    icon: <Psychology />,
    path: routes.empowerMe.training,
    roles: [],
  },
  {
    name: 'Organisations',
    icon: <ApartmentIcon />,
    path: routes.empowerMe.organisations,
    roles: ['organisation:read'],
  },
  {
    name: 'Organisation Groups',
    icon: <GroupsIcon />,
    path: routes.empowerMe.organisationGroups,
    roles: ['organisationgroup:read'],
  },
  {
    name: 'Upload Users',
    icon: <Upload />,
    path: routes.empowerMe.uploadUser,
    roles: ['uploaduser:write'],
  },
  {
    name: 'Event Review Options',
    icon: <ChecklistIcon />,
    path: routes.empowerMe.eventReviewOptions,
    roles: ['organisationgroup:read'],
  },
];

export const recruitMeLinks: NavigationLink[] = [
  {
    name: 'Recruit Me',
    icon: <CalendarToday />,
    enableClick: false,
    path: routes.recruitMe.get(),
    roles: recruitMeViewRoles,
  },
  {
    name: 'Assessment Chat',
    icon: <ChatIcon />,
    path: routes.recruitMe.assessmentChat,
    roles: ['recruitmeassessments:write'],
  },
  {
    name: 'Candidates',
    icon: <Badge />,
    path: routes.recruitMe.candidates,
    roles: ['recruitmecandidate:read'],
  },
];

export const trainMeLinks: NavigationLink[] = [
  {
    name: 'Assessments',
    icon: <Badge />,
    path: routes.trainMe.assessments,
    roles: ['trainmereports:read'],
  },
  {
    name: 'Learners',
    icon: <Badge />,
    path: routes.trainMe.candidates,
    roles: [],
  },
  {
    name: 'Scheduler',
    icon: <EditCalendar />,
    path: routes.trainMe.scheduler,
    roles: [],
  },
  {
    name: 'Learner Upload',
    icon: <Upload />,
    path: routes.trainMe.uploadUser,
    roles: [],
  },
];
