import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {OptixTableDataTypeBooleanCheckboxEditor} from './OptixTableDataTypeBooleanCheckboxEditor';
import {OptixTableDataTypeBooleanCheckboxFormatter} from './OptixTableDataTypeBooleanCheckboxFormatter';

export default function OptixTableDataTypeBooleanProvider(
  props: Pick<DataTypeProviderProps, 'for'>,
) {
  return (
    <DataTypeProvider
      editorComponent={OptixTableDataTypeBooleanCheckboxEditor}
      formatterComponent={OptixTableDataTypeBooleanCheckboxFormatter}
      {...props}
    />
  );
}
