import {
  PagingPanel,
  type PagingPanelProps,
} from '@devexpress/dx-react-grid-material-ui';

import {OptixTablePagingContainer} from './OptixTablePagingContainer';

export default function OptixTablePaging(props: PagingPanelProps) {
  return (
    <PagingPanel
      pageSizes={[5, 25, 50, 100]}
      containerComponent={OptixTablePagingContainer}
      {...props}
    />
  );
}
