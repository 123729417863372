import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {OptixTableDataTypeStringEditor} from './OptixTableDataTypeStringEditor';
import {OptixTableDataTypeStringFormatter} from './OptixTableDataTypeStringFormatter';

export default function OptixTableDataTypeStringProvider(
  props: Pick<DataTypeProviderProps, 'for'>,
) {
  return (
    <DataTypeProvider
      editorComponent={OptixTableDataTypeStringEditor}
      formatterComponent={OptixTableDataTypeStringFormatter}
      {...props}
    />
  );
}
