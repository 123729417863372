import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {OptixTableDataTypeNumberEditor} from './OptixTableDataTypeNumberEditor';
import {OptixTableDataTypeNumberFormatter} from './OptixTableDataTypeNumberFormatter';

export default function OptixTableDataTypeNumberProvider(
  props: Pick<DataTypeProviderProps, 'for'>,
) {
  return (
    <DataTypeProvider
      editorComponent={OptixTableDataTypeNumberEditor}
      formatterComponent={OptixTableDataTypeNumberFormatter}
      {...props}
    />
  );
}
