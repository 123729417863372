import {
  type ChangeEvent,
  type KeyboardEventHandler,
  useCallback,
  useMemo,
} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';

import {OptixTextField} from '../../../textField';
import {useOptixTableContext} from '../../OptixTableContext';

export function OptixTableDataTypeStringEditor({
  row,
  value,
  column,
  onValueChange,
  onBlur,
  onFocus,
  onKeyDown,
}: DataTypeProvider.ValueEditorProps) {
  const {config} = useOptixTableContext();

  const columnConfig = config.columns.find((c) => c.name === column.name);
  const fieldValue = useMemo(() => {
    if (columnConfig?.getFieldValue != null) {
      return columnConfig.getFieldValue(row);
    }

    if (columnConfig?.getValue != null) {
      return columnConfig.getValue(row);
    }

    return value;
  }, [columnConfig, row, value]);

  const isEnableEditing = useMemo(() => {
    if (
      columnConfig?.enableEditing === false ||
      columnConfig?.enableEditing === undefined
    ) {
      return false;
    }
    return true;
  }, [columnConfig]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onValueChange(event.target.value);
    },
    [onValueChange],
  );

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        onKeyDown({key: event.key});
      }
    },
    [onKeyDown],
  );

  return (
    <OptixTextField
      size="small"
      name={column.name}
      disabled={!isEnableEditing}
      value={fieldValue ?? ''}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
    />
  );
}
