import {type PropsWithChildren, useCallback} from 'react';

import {type Column} from '@devexpress/dx-react-grid';
import {Grid, type GridProps} from '@devexpress/dx-react-grid-material-ui';

import {OptixTableGridRoot} from './OptixTableGridRoot';
import {useOptixTableContext} from '../OptixTableContext';

export interface OptixTableGridProps<TData = object> {
  columns: Column[];
  rows: TData[];
}

export function OptixTableGrid<TData = object>({
  children,
  rows,
  columns,
}: PropsWithChildren<OptixTableGridProps<TData>>) {
  const {rowId} = useOptixTableContext();

  const getRowId = useCallback<Required<GridProps>['getRowId']>(
    (row) => row[rowId],
    [rowId],
  );

  return (
    <Grid
      columns={columns}
      rows={rows}
      getRowId={getRowId}
      rootComponent={OptixTableGridRoot}
    >
      {children}
    </Grid>
  );
}
