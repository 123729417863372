import {type DataTypeProvider} from '@devexpress/dx-react-grid';

import {useOptixTableContext} from '../../OptixTableContext';

export function OptixTableDataTypeSelectFormatter({
  value,
  row,
  column,
}: DataTypeProvider.ValueFormatterProps) {
  const {config} = useOptixTableContext();
  const columnConfig = config.columns.find((c) => c.name === column.name);
  if (columnConfig?.getDisplayValue == null) {
    return value;
  }

  return columnConfig.getDisplayValue(row);
}
