import {type DataTypeProvider} from '@devexpress/dx-react-grid';
import Stack from '@mui/material/Stack';

import {OptixTableCancelAction} from './components/OptixTableCancelAction';
import {OptixTableSaveAction} from './components/OptixTableSaveAction';

export default function OptixTableActionsProviderEditor({
  row,
}: DataTypeProvider.ValueEditorProps) {
  return (
    <Stack direction="row" spacing={0.5}>
      <OptixTableSaveAction row={row} />
      <OptixTableCancelAction row={row} />
    </Stack>
  );
}
