import {
  Configuration,
  UserApi,
  DriverApi,
  EventBehavioursApi,
  OrganisationGroupApi,
  EventActivityReasonCodeApi,
  EventDriverStatusApi,
  EmpowerMeApi,
  OrganisationApi,
  RecruitMeApi,
  EscalationLevelsApi,
  MobileAuthApi,
  TrainingApi,
  TrainMeOptixApi,
  TrainMeApi,
  ValidityPeriodsControllerApi,
  EventExternalStatusApi,
  EventBehaviourDriverVisibleApi,
  EventBehaviourEscalationLevelApi,
  EventExternalStatusDriverVisibleApi,
} from '@onroadvantage/optix-safeandsound-openapi';

import {config} from './modules/common/services/configService';
import {useAuthStore} from './modules/common/stores/authStore';

const getToken = async () => {
  try {
    let token = '-';
    const authToken = useAuthStore.getState().token;
    if (authToken != null) {
      token = authToken;
    }
    return `Bearer ${token}`;
  } catch (e) {
    return '-';
  }
};

const configuration = new Configuration({
  basePath: config.serverUri,
  accessToken: getToken,
  headers: {Origin: window.origin},
});

export const driverApi = new DriverApi(configuration);
export const escalationLevelsApi = new EscalationLevelsApi(configuration);
export const eventActivityReasonCodeApi = new EventActivityReasonCodeApi(
  configuration,
);
export const eventBehavioursApi = new EventBehavioursApi(configuration);
export const eventDriverStatusApi = new EventDriverStatusApi(configuration);
export const eventBehaviourDriverVisibleApi =
  new EventBehaviourDriverVisibleApi(configuration);
export const eventBehaviourEscalationLevelApi =
  new EventBehaviourEscalationLevelApi(configuration);
export const evenBehaviourExternalStatusDriverVisible =
  new EventExternalStatusDriverVisibleApi(configuration);
export const userApi = new UserApi(configuration);
export const empowerMeApi = new EmpowerMeApi(configuration);
export const eventExternalStatusApi = new EventExternalStatusApi(configuration);
export const organisationApi = new OrganisationApi(configuration);
export const recruitMeApi = new RecruitMeApi(configuration);
export const mobileAuthApi = new MobileAuthApi(configuration);
export const organisationGroupApi = new OrganisationGroupApi(configuration);
export const trainingApi = new TrainingApi(configuration);
export const trainMeApi = new TrainMeApi(configuration);
export const trainMeOptixApi = new TrainMeOptixApi(configuration);
export const validityPeriodsControllerApi = new ValidityPeriodsControllerApi(
  configuration,
);
