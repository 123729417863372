import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {type SelectChangeEvent} from '@mui/material/Select';

import {type OptixAutocompleteOption} from '../autocomplete/OptixAutocomplete';

interface DropDown {
  label: string | undefined;
  value: string | undefined;
}
export interface OptixSelectProps {
  value?: string;
  options: DropDown[] | OptixAutocompleteOption[];
  label?: string;
  onChange: (event: SelectChangeEvent) => void;
  size?: 'small' | 'medium' | undefined;
  isDisabled?: boolean;
  width?: number;
}

export function OptixSelect({
  value,
  label,
  options = [
    {label: 'Text 1', value: '0'},
    {label: 'Text 2', value: '1'},
    {label: 'Text 3', value: '2'},
  ],
  onChange,
  size,
  width = 50,
  isDisabled,
}: OptixSelectProps) {
  return (
    <Box sx={{minWidth: width}}>
      <FormControl size="medium" fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          disabled={isDisabled}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={onChange}
          size={size}
        >
          {options.map((obj) => (
            <MenuItem
              key={obj?.value?.toString()}
              value={obj?.value?.toString()}
            >
              {obj.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
