const ROLES = [
  'bookings:write',
  'driver:read',
  'driverassign:write',
  'driverrank:read',
  'empowerme:read',
  'empowermeevents:read',
  'empowermeranking:read',
  'enrollments:read',
  'event:read',
  'eventactivity:read',
  'eventactivityreasoncode:delete',
  'eventactivityreasoncode:read',
  'eventactivityreasoncode:write',
  'eventbehaviour:read',
  'eventbehaviour:read',
  'eventbehaviour:write',
  'eventdriverstatus:read',
  'eventdriverstatus:write',
  'eventexternalstatus:read',
  'eventexternalstatus:write',
  'eventexternalstatusdrivervisible:read',
  'eventexternalstatusdrivervisible:write',
  'eventnote:read',
  'eventtype:read',
  'file:write',
  'migrations:read',
  'migrations:write',
  'organisation:read',
  'organisationconfig:read',
  'organisationgroup:read',
  'organisationgroupconfig:read',
  'organisationme:read',
  'recruitme:read',
  'recruitme:write',
  'recruitmeassessments:write',
  'recruitmecandidate:read',
  'recruitmecandidate:write',
  'resolveeventdriverstatus:write',
  'role:read',
  'trainingbehaviourgroup:read',
  'trainingbehaviourgroup:write',
  'trainingbehaviourtrainingcourse:write',
  'trainingcourse:read',
  'trainingcoursetype:read',
  'trainingcoursetype:write',
  'trainmeanswers:read',
  'trainmeanswers:write',
  'trainmecategories:read',
  'trainmecoursecontent:write',
  'trainmecourses:read',
  'trainmecourses:write',
  'trainmeenrollment:read',
  'trainmeenrollment:write',
  'trainmeenrollmentstatus:write',
  'trainmequestions:read',
  'trainmequestions:write',
  'trainmereports:read',
  'uploaduser:write',
  'user:read',
  'user:write',
  'userme:read',
  'userqr:read',
  'userqr:write',
  'vehicle:read',
] as const;

export type Role = (typeof ROLES)[number];

export const empowerMeViewRoles: Role[] = [
  'driver:read',
  'empowerme:read',
  'empowermeevents:read',
  'empowermeranking:read',
  'eventactivityreasoncode:read',
  'eventbehaviour:read',
  'organisation:read',
  'organisationgroup:read',
  'trainingcourse:read',
  'uploaduser:write',
];

export const recruitMeViewRoles: Role[] = [
  'recruitme:read',
  'recruitmeassessments:write',
  'recruitmecandidate:read',
];

export const trainMeViewRoles: Role[] = [];
