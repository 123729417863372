import {Suspense} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';
import Stack from '@mui/material/Stack';

import OptixTableDeleteAction from './components/OptixTableDeleteAction';
import OptixTableEditAction from './components/OptixTableEditAction';
import OptixTableViewQRAction from './components/OptixTableViewQRButton';
import {useOptixTableActionsProviderContext} from './OptixTableActionsProviderContext';

export default function OptixTableActionsProviderFormatter({
  row,
}: DataTypeProvider.ValueFormatterProps) {
  const {enableEdit, enableDelete, enableQR} =
    useOptixTableActionsProviderContext();
  return (
    <Stack direction="row" p={0} spacing={0.5}>
      <Suspense fallback={null}>
        {enableEdit && <OptixTableEditAction row={row} />}
        {enableDelete && <OptixTableDeleteAction row={row} />}
        {enableQR && <OptixTableViewQRAction row={row} />}
      </Suspense>
    </Stack>
  );
}
