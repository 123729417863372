import {
  TableHeaderRow,
  type TableHeaderRowProps,
} from '@devexpress/dx-react-grid-material-ui';

import {OptixTableHeaderCell} from './OptixTableHeaderCell';

export function OptixTableHeaderRow(props: TableHeaderRowProps) {
  return <TableHeaderRow cellComponent={OptixTableHeaderCell} {...props} />;
}
