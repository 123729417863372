import {type ChangeEvent, useCallback, useMemo} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';

import {OptixCheckbox} from '../../../checkbox/OptixCheckbox';
import {useOptixTableContext} from '../../OptixTableContext';

export function OptixTableDataTypeBooleanCheckboxEditor({
  value,
  onValueChange,
  column,
}: DataTypeProvider.ValueEditorProps) {
  const {config} = useOptixTableContext();

  const columnConfig = config.columns.find((c) => c.name === column.name);

  const isEnableEditing = useMemo(() => {
    if (
      columnConfig?.enableEditing === false &&
      columnConfig?.enableEditing === undefined
    ) {
      return false;
    }

    return true;
  }, [columnConfig]);

  const handleChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onValueChange(checked);
    },
    [onValueChange],
  );

  return (
    <OptixCheckbox
      disabled={!isEnableEditing}
      checked={value}
      onChange={handleChange}
    />
  );
}
