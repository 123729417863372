import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {OptixTableDataTypeSelectEditor} from './OptixTableDataTypeSelectEditor';
import {OptixTableDataTypeSelectFormatter} from './OptixTableDataTypeSelectFormatter';

export default function OptixTableDataTypeSelectProvider(
  props: Pick<DataTypeProviderProps, 'for'>,
) {
  return (
    <DataTypeProvider
      editorComponent={OptixTableDataTypeSelectEditor}
      formatterComponent={OptixTableDataTypeSelectFormatter}
      {...props}
    />
  );
}
