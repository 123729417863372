import {type ReactNode} from 'react';

import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import {type TextFieldProps} from '@mui/material/TextField';

import {
  StyledOptixInputLabel,
  StyledOptixTextField,
} from './OptixTextField.style';

export type OptixTextFieldProps = TextFieldProps & {
  end?: ReactNode;
  start?: ReactNode;
};

export function OptixTextField({
  label,
  end,
  start,
  ...props
}: OptixTextFieldProps) {
  return (
    <FormControl variant="standard" fullWidth={props.fullWidth}>
      {label != null ? (
        <StyledOptixInputLabel shrink htmlFor="optix-input">
          {label}
        </StyledOptixInputLabel>
      ) : null}
      <StyledOptixTextField
        size="small"
        {...props}
        InputProps={{
          endAdornment:
            end != null ? (
              <InputAdornment position="end">{end}</InputAdornment>
            ) : undefined,
          startAdornment:
            start != null ? (
              <InputAdornment position="start">{start}</InputAdornment>
            ) : undefined,
          ...props.InputProps,
        }}
      />
    </FormControl>
  );
}
