import {
  useCallback,
  type MouseEventHandler,
  type PropsWithChildren,
} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {OptixButton, type OptixButtonProps} from '../button';

interface OptixDialogAction {
  title?: OptixButtonProps['title'];
  color?: OptixButtonProps['color'];
  size?: OptixButtonProps['size'];
  variant?: OptixButtonProps['variant'];
  sx?: OptixButtonProps['sx'];
  className?: OptixButtonProps['className'];
  startIcon?: OptixButtonProps['startIcon'];
  endIcon?: OptixButtonProps['endIcon'];
  disabled?: OptixButtonProps['disabled'];
  loading?: OptixButtonProps['loading'];
  loadingText?: OptixButtonProps['loadingText'];
  type?: OptixButtonProps['type'];
}

export interface OptixDialogProps {
  open: boolean;
  onClose: (
    event: object,
    reason: 'backdropClick' | 'escapeKeyDown' | 'acceptClick' | 'denyClick',
  ) => void;
  title: string;
  acceptText?: string;
  enableAccept?: boolean;
  enableCloseOnAccept?: boolean;
  denyText?: string;
  enableDeny?: boolean;
  enableCloseOnDeny?: boolean;
  onAccept?: MouseEventHandler<HTMLButtonElement>;
  onDeny?: MouseEventHandler<HTMLButtonElement>;
  message?: string;
  acceptProps?: OptixDialogAction;
  denyProps?: OptixDialogAction;
}

export function OptixDialog({
  open,
  onClose,
  acceptText = 'Accept',
  enableAccept = false,
  enableCloseOnAccept = true,
  denyText = 'Deny',
  enableDeny = false,
  enableCloseOnDeny = true,
  onAccept,
  onDeny,
  title,
  message,
  acceptProps,
  denyProps,
  children,
}: PropsWithChildren<OptixDialogProps>) {
  const handleAccept = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      if (enableCloseOnAccept) {
        onClose({}, 'acceptClick');
      }

      if (onAccept != null) {
        onAccept(event);
      }
    },
    [enableCloseOnAccept, onAccept, onClose],
  );

  const handleDeny = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      if (enableCloseOnDeny) {
        onClose({}, 'denyClick');
      }

      if (onDeny != null) {
        onDeny(event);
      }
    },
    [enableCloseOnDeny, onDeny, onClose],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={`optix-dialog-${title}`}
      aria-describedby={`optix-dialog-${message ?? title}`}
    >
      <DialogTitle id={`optix-dialog-${title}`}>{title}</DialogTitle>
      {(message != null || children != null) && (
        <DialogContent>
          {message != null && (
            <DialogContentText id={`optix-dialog-${message}`}>
              {message}
            </DialogContentText>
          )}
          {children}
        </DialogContent>
      )}
      <DialogActions sx={{gap: 1}}>
        {enableDeny && (
          <OptixButton
            text={denyText}
            variant="outlined"
            color="primary"
            {...acceptProps}
            onClick={handleDeny}
          />
        )}
        {enableAccept && (
          <OptixButton
            text={acceptText}
            variant="contained"
            {...denyProps}
            onClick={handleAccept}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
