import {useCallback} from 'react';

import Delete from '@mui/icons-material/Delete';

import {OptixDialogButton} from '../../../../dialog/OptixDialogButton';
import {useOptixTableContext} from '../../../OptixTableContext';

interface OptixTableDeleteActionProps<TData extends object> {
  row: TData;
}

export function OptixTableDeleteAction<TData extends object>({
  row,
}: OptixTableDeleteActionProps<TData>) {
  const {onDelete} = useOptixTableContext();

  const handleDelete = useCallback(() => {
    if (onDelete != null) {
      onDelete(row);
    }
  }, [onDelete, row]);

  return (
    <OptixDialogButton
      text="Delete"
      dialogTitle="Are you sure you want to delete?"
      startIcon={<Delete />}
      onAccept={handleDelete}
      size="small"
      variant="text"
      color="inherit"
      acceptText="Delete"
      denyText="Cancel"
      enableAccept
      enableDeny
    />
  );
}

export default OptixTableDeleteAction;
