import {useCallback, useMemo, type KeyboardEvent} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';

import {OptixAutocomplete} from '../../../autocomplete/OptixAutocomplete';
import {useOptixTableContext} from '../../OptixTableContext';

export function OptixTableDataTypeAutocompleteEditor({
  value,
  onBlur,
  onFocus,
  onKeyDown,
  column,
}: DataTypeProvider.ValueEditorProps) {
  const {config} = useOptixTableContext();
  const columnConfig = useMemo(
    () => config.columns.find(({name}) => column.name === name),
    [column.name, config.columns],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        onKeyDown({key: event.key}); // Literal type assertion for key property
      }
    },
    [onKeyDown],
  );

  return (
    <OptixAutocomplete
      size="small"
      value={value}
      onInputChange={(e) => {
        if ('value' in e.target) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          columnConfig?.onInputChange(e.target.value);
        }
        // console.log({e: 'value' in e.target ? e.target.value : ''});
      }}
      // onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      onChange={() => {}}
      options={
        columnConfig != null &&
        'type' in columnConfig &&
        columnConfig.type === 'autocomplete' &&
        columnConfig.options != null
          ? columnConfig.options
          : []
      }
    />
  );
}
