import {useCallback, useState} from 'react';

import Check from '@mui/icons-material/Check';

import {OptixTableActionsButton} from './OptixTableActionsButton';
import {isObjectWithKeys} from '../../../../../../modules/common/utils/unknownhelper';
import {useOptixTableContext} from '../../../OptixTableContext';
import {useOptixTableActionsProviderContext} from '../OptixTableActionsProviderContext';

interface OptixTableSaveActionProps<TData extends Record<string, unknown>> {
  row: TData;
}

export function OptixTableSaveAction<TData extends Record<string, unknown>>({
  row,
}: OptixTableSaveActionProps<TData>) {
  const {onAdd, onEdit, onInlineEdit, rowId} = useOptixTableContext();
  const {setEditingRows, setAddedRows, rowChanges} =
    useOptixTableActionsProviderContext();
  const [submitting, setSubmitting] = useState(false);

  const handleSave = useCallback(async () => {
    setSubmitting(true);
    try {
      const id = row[rowId] as string;
      if (onAdd != null && id == null) {
        onAdd();
        setAddedRows((prevState) => prevState.filter((state) => state !== row));
      } else if (onEdit != null) {
        onEdit(row, () => {});
        setEditingRows((prevState) =>
          prevState.filter(
            (state) => isObjectWithKeys(state, rowId) && state[rowId] !== id,
          ),
        );
      } else if (onInlineEdit != null) {
        const curRowChanges = rowChanges[row[rowId] as string];

        // TODO Handle response
        await onInlineEdit({id, rowChanges: curRowChanges});

        setEditingRows((prevState) => {
          return prevState.filter(
            (state) => isObjectWithKeys(state, rowId) && state[rowId] !== id,
          );
        });
      }
    } finally {
      setSubmitting(false);
    }
  }, [
    rowId,
    row,
    onAdd,
    onEdit,
    onInlineEdit,
    setEditingRows,
    setAddedRows,
    rowChanges,
  ]);

  return (
    <OptixTableActionsButton
      text="Save"
      icon={<Check />}
      loadingText="Saving"
      loading={submitting}
      onClick={handleSave}
    />
  );
}
