import {useCallback} from 'react';

import Edit from '@mui/icons-material/Edit';

import {OptixTableActionsButton} from './OptixTableActionsButton';
import {useOptixTableContext} from '../../../OptixTableContext';
import {useOptixTableActionsProviderContext} from '../OptixTableActionsProviderContext';

interface OptixTableEditActionProps<TData extends object> {
  row: TData;
}

export function OptixTableEditAction<TData extends object>({
  row,
}: OptixTableEditActionProps<TData>) {
  const {onInlineEdit} = useOptixTableContext();
  if (onInlineEdit != null) {
    return <OptixTableInlineEditAction row={row} />;
  }
  return <OptixTableDialogEditAction row={row} />;
}

function OptixTableInlineEditAction<TData extends object>({
  row,
}: OptixTableEditActionProps<TData>) {
  const {setEditingRows} = useOptixTableActionsProviderContext();

  const handleEditRow = useCallback(() => {
    if (row != null) {
      setEditingRows((prevState) => [...prevState, row]);
    }
  }, [row, setEditingRows]);

  return (
    <OptixTableActionsButton
      text="Edit"
      icon={<Edit />}
      onClick={handleEditRow}
    />
  );
}

function OptixTableDialogEditAction<TData extends object>({
  row,
}: OptixTableEditActionProps<TData>) {
  const {onDialogEdit} = useOptixTableContext();

  const handleEditRow = useCallback(() => {
    if (onDialogEdit != null && row != null) {
      onDialogEdit(row, () => {});
    }
  }, [onDialogEdit, row]);

  return (
    <OptixTableActionsButton
      text="Edit"
      icon={<Edit />}
      onClick={handleEditRow}
    />
  );
}

export default OptixTableEditAction;
