import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useContext,
} from 'react';

export interface OptixTableActionsProviderProps<TData = object> {
  enableEdit: boolean;
  enableAdd: boolean;
  enableDelete: boolean;
  enableQR: boolean;
  editingRows: TData[];
  rowChanges: Record<string, unknown>;
  setEditingRows: Dispatch<SetStateAction<TData[]>>;
  addedRows: TData[];
  setAddedRows: Dispatch<SetStateAction<TData[]>>;
  expandedRowsId: Array<number | string>;
  setExpandedRowsId: Dispatch<SetStateAction<Array<number | string>>>;
}

export const OptixTableActionsProviderContext =
  createContext<OptixTableActionsProviderProps<unknown> | null>(null);

export const useOptixTableActionsProviderContext = () => {
  const optixTableContext =
    useContext<OptixTableActionsProviderProps<unknown> | null>(
      OptixTableActionsProviderContext,
    );

  if (optixTableContext == null) {
    throw new Error(
      'useOptixTableActionsProviderContext has to be used within <OptixTableActionsProviderContext.Provider>',
    );
  }

  return optixTableContext;
};
