import {type ReactNode} from 'react';

import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';

interface OptixTableToolbarCustomProps {
  CustomActions: ReactNode;
}

export default function OptixTableToolbarCustom({
  CustomActions,
}: OptixTableToolbarCustomProps) {
  return (
    <Plugin dependencies={[{name: 'Toolbar'}]} name="TableToolbarCustom">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        {CustomActions}
      </Template>
    </Plugin>
  );
}
