import {type ReactNode} from 'react';

import {OptixButton, type OptixButtonProps} from '../../../../button';
import {useOptixTableContext} from '../../../OptixTableContext';

interface OptixTableActionsButtonProps {
  text: string;
  icon: ReactNode;
  loading?: boolean;
  loadingText?: string;
  onClick: OptixButtonProps['onClick'];
}

export function OptixTableActionsButton({
  text,
  icon,
  loading = false,
  loadingText,
  onClick,
}: OptixTableActionsButtonProps) {
  const {loading: loadingTable} = useOptixTableContext();
  return (
    <OptixButton
      text={text}
      startIcon={icon}
      onClick={onClick}
      disabled={loadingTable}
      loading={loading}
      loadingText={loadingText}
      color="inherit"
      variant="text"
      size="small"
    />
  );
}
