import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {OptixTableDataTypeTimeEditor} from './OptixTableDataTypeTimeEditor';
import {OptixTableDataTypeTimeFormatter} from './OptixTableDataTypeTimeFormatter';

export default function OptixTableDataTypeTimeProvider(
  props: Pick<DataTypeProviderProps, 'for'>,
) {
  return (
    <DataTypeProvider
      editorComponent={OptixTableDataTypeTimeEditor}
      formatterComponent={OptixTableDataTypeTimeFormatter}
      {...props}
    />
  );
}
